import { Collection } from '@dewantara-types/apis';
import { AuthCredential, AuthToken, User, UserProduct } from '@dewantara-types/models';
import { RootState } from '@dewantara-types/store';
import { ActionRegisterPayload } from '@dewantara-types/store/auth-action-type';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  register as registerApi,
  login as loginApi,
  me,
  refresh,
  fetchUserProductCollection,
} from 'hooks/use-api-request';
import { ErrorDev } from 'logging/Logging';
import { authActions } from 'store/slices/auth-slice';

export const register = createAsyncThunk(
  'auth/register',
  async (payload: ActionRegisterPayload, { dispatch }) => {
    const { auth, onSuccess } = payload;

    dispatch(authActions.authenticating(true));
    try {
      const authToken: AuthToken | null = await registerApi(auth);
      if (authToken && onSuccess) onSuccess();
    } catch (e) {
      ErrorDev(e);
    } finally {
      dispatch(authActions.authenticating(false));
    }
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async (payload: AuthCredential, { dispatch }) => {
    dispatch(authActions.authenticating(true));

    const authToken: AuthToken | null = await loginApi(payload);
    if (authToken) {
      dispatch(
        authActions.setTokens({
          accessToken: { token: authToken.accessToken, expiresIn: authToken.expiresIn },
          refreshToken: {
            token: authToken.refreshToken,
            expiresIn: authToken.refreshExpiresIn,
          },
        })
      );
    } else {
      ErrorDev('Something went wrong while logging user in');
    }
    dispatch(authActions.authenticating(false));
  }
);

export const fetchUser = createAsyncThunk<any, void, { state: RootState }>(
  'auth/fetchUser',
  async (_, { getState, dispatch }) => {
    const { accessToken } = getState().auth;

    if (accessToken) {
      await dispatch(verifyAccessToken());

      const user: User | null = await me();
      if (user) {
        dispatch(authActions.setUser(user));
        if (user.children && user.children.length > 0)
          dispatch(authActions.setActiveChild(user.children[0]));

        const userProductCollection: Collection<UserProduct> | null =
          await fetchUserProductCollection();

        dispatch(authActions.setUserProducts(userProductCollection?.data ?? []));
      } else {
        ErrorDev('Something went wrong fetching user data.');
      }
    }
  }
);

export const verifyAccessToken = createAsyncThunk<any, void, { state: RootState }>(
  'auth/refresh',
  async (_, { getState, dispatch }) => {
    const state = getState();
    const { accessToken, refreshToken } = state.auth;

    if (accessToken && refreshToken && accessToken.expiresIn <= Date.now()) {
      const newAuthToken: AuthToken | null = await refresh(refreshToken.token);
      if (newAuthToken) {
        dispatch(
          authActions.setTokens({
            accessToken: {
              token: newAuthToken.accessToken,
              expiresIn: newAuthToken.expiresIn,
            },
            refreshToken: {
              token: newAuthToken.refreshToken,
              expiresIn: newAuthToken.refreshExpiresIn,
            },
          })
        );
      } else {
        ErrorDev('Something went wrong getting new access token.');
        dispatch(authActions.clearAuth());
      }
    }
  }
);
