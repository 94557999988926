import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card, Typography } from '@material-tailwind/react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { ApplicationRoutes } from 'dewantara-constants';
import { Props } from '@dewantara-types/props';

const NAV_ITEMS: { route: string; icon: any; label: string }[] = [
  {
    route: ApplicationRoutes.DASHBOARD_ROUTE,
    icon: HomeOutlinedIcon,
    label: 'Beranda',
  },
  {
    route: ApplicationRoutes.ROOT_STORE_ROUTE,
    icon: StorefrontOutlinedIcon,
    label: 'Toko',
  },
  {
    route: ApplicationRoutes.ROOT_USER_ROUTE,
    icon: PersonOutlineOutlinedIcon,
    label: 'Pengguna',
  },
];

export function NavigationBar(props: Props): React.ReactElement {
  const location = useLocation();
  return (
    <React.Fragment>
      <div
        className={`fixed bottom-4 xl:bottom-full xl:top-8 w-full xl:w-fit px-5 sm:px-8 md:px-12 ${props.className}`}
      >
        <Card className="xl:w-screen xl:max-w-5xl px-8 py-2.5 xl:py-4 shadow-gray-500 rounded-3xl">
          <div className="flex items-center justify-between sm:justify-around font-medium text-d-blue-primary text-xl">
            {NAV_ITEMS.map((item) => (
              <Link
                key={item.route}
                to={item.route}
                state={{ prevPath: location.pathname }}
                className="flex flex-col xl:flex-row md:gap-1 lg:gap-2 items-center justify-center"
              >
                <span
                  className={`pt-0 md:pt-1 p-1 rounded-lg ${
                    location.pathname === item.route
                      ? 'bg-d-blue-primary text-d-gray-primary'
                      : 'text-d-blue-primary'
                  }`}
                >
                  <item.icon className="!h-6 md:!h-8 lg:!h-10 !w-6 md:!w-8 lg:!w-10" />
                </span>
                <Typography
                  className={`text-sm md:text-lg lg:text-xl ${
                    location.pathname === item.route && 'font-bold'
                  }`}
                >
                  {item.label}
                </Typography>
              </Link>
            ))}
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default NavigationBar;
