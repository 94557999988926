import { Collection } from '@dewantara-types/apis';
import { IUseApiRequest } from '@dewantara-types/hooks';
import { FileData } from '@dewantara-types/models';
import { AppDispatch } from '@dewantara-types/store';
import { useDispatch } from 'react-redux';
import { verifyAccessToken } from 'store/actions';
import {
  _fetchCollection,
  _fetchData,
  _insertData,
  _updateData,
  _deleteData,
  _uploadFile,
} from './crud';

export const useApiRequest = (): IUseApiRequest => {
  const dispatch = useDispatch<AppDispatch>();

  async function verifyUserToken() {
    await dispatch(verifyAccessToken());
  }

  async function fetchCollection<T>(route: string): Promise<Collection<T> | null> {
    return _fetchCollection<T>(route, verifyUserToken);
  }

  async function fetchData<T>(route: string): Promise<T | null> {
    return _fetchData<T>(route, verifyUserToken);
  }

  async function insertData<T>(route: string, payload: any): Promise<T | null> {
    return _insertData<T>(route, payload, verifyUserToken);
  }

  async function updateData<T>(route: string, payload: any): Promise<T | null> {
    return _updateData<T>(route, payload, verifyUserToken);
  }

  async function deleteData(route: string): Promise<boolean> {
    return _deleteData(route, verifyUserToken);
  }

  async function uploadFile<T>(route: string, payload: FileData): Promise<T | null> {
    return _uploadFile<T>(route, payload, verifyUserToken);
  }

  return {
    fetchCollection,
    fetchData,
    insertData,
    updateData,
    deleteData,
    uploadFile,
  };
};

export * from './auth';
export {
  _fetchCollection,
  _fetchData,
  _insertData,
  _updateData,
  _deleteData,
  _uploadFile,
};
