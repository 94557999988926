import { Props } from '@dewantara-types/props';
import { Typography } from '@material-tailwind/react';
import React from 'react';

export function DewantaraLabel(props: Props): React.ReactElement {
  const { className, children } = props;

  const textSizeClass = 'text-base sm:text-xl md:text-2xl lg:text-3xl';

  return (
    <Typography
      className={`text-d-blue-primary font-medium ${textSizeClass} ${className}`}
    >
      {children}
    </Typography>
  );
}

export default DewantaraLabel;
