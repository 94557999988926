import { SectionTitleProps } from '@dewantara-types/props';
import { DewantaraTitle } from 'components/base';

export function SectionTitle(props: SectionTitleProps): React.ReactElement {
  const { className, title, iconSrc, iconAlt, color, size = 'md' } = props;

  const borderColor: string = color
    ? `border-d-${color}-primary`
    : 'border-d-gray-primary';

  const borderSize: string =
    size === 'md'
      ? 'w-[80%] border-2 sm:border-[.2rem] lg:border-[.3rem] xl:border-[.2rem]'
      : 'w-[60%] border-[.15rem] sm:border-[.15rem]';

  const itemsGap: string = size === 'md' ? 'gap-4 lg:gap-5' : 'gap-3';

  return (
    <div className={`flex flex-row items-center ${itemsGap} ${className}`}>
      {iconSrc && (
        <img
          className="w-10 sm:w-12 md:w-16 lg:w-20 xl:w-16 h-10 sm:h-12 md:h-16 lg:h-20 xl:h-16"
          src={iconSrc}
          alt={iconAlt ?? ''}
        />
      )}

      <div className="flex flex-col gap-1">
        <DewantaraTitle className="md:mt-1 md:mb-2" size={size} color={color}>
          {title}
        </DewantaraTitle>
        <hr className={`${borderColor} ${borderSize} rounded-full`} />
      </div>
    </div>
  );
}

export default SectionTitle;
