import { Collection } from '@dewantara-types/apis';
import { AuthCredential, AuthToken, User, UserProduct } from '@dewantara-types/models';
import axios, { AxiosResponse } from 'axios';
import { APIRoutes } from 'dewantara-constants';
import { ErrorDev, LogDev } from 'logging/Logging';

export async function verifyEmail(email: string): Promise<boolean> {
  try {
    await axios.get(`${APIRoutes.AUTH_ROUTE}/${APIRoutes.PATH.VERIFY_EMAIL}/${email}`);
    return true;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      ErrorDev(`Axios error: ${e.message}`);
    } else {
      ErrorDev(`Unexpected error: ${e instanceof Error ? e.message : String(e)}`);
    }
    return false;
  }
}

export async function register(auth: AuthCredential): Promise<AuthToken | null> {
  try {
    const res: AxiosResponse = await axios.post(
      `${APIRoutes.AUTH_ROUTE}/${APIRoutes.PATH.REGISTER}`,
      auth
    );
    const responseData: AuthToken = await res.data;

    LogDev(responseData);
    return responseData;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      ErrorDev(`Axios error: ${e.message}`);
    } else {
      ErrorDev(`Unexpected error: ${e instanceof Error ? e.message : String(e)}`);
    }
    return null;
  }
}

export async function login(auth: AuthCredential): Promise<AuthToken | null> {
  try {
    const res: AxiosResponse = await axios.post(
      `${APIRoutes.AUTH_ROUTE}/${APIRoutes.PATH.LOGIN}`,
      auth
    );
    const responseData: AuthToken = await res.data;

    LogDev(responseData);
    return responseData;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      ErrorDev(`Axios error: ${err.message}`);
    } else {
      ErrorDev(`Unexpected error: ${err instanceof Error ? err.message : String(err)}`);
    }
    return null;
  }
}

export async function refresh(refreshToken: string): Promise<AuthToken | null> {
  try {
    const res: AxiosResponse = await axios.post(
      `${APIRoutes.AUTH_ROUTE}/${APIRoutes.PATH.REFRESH}`,
      { refreshToken: refreshToken }
    );
    const responseData: AuthToken = await res.data;

    LogDev(responseData);
    return responseData;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      ErrorDev(`Axios error: ${err.message}`);
    } else {
      ErrorDev(`Unexpected error: ${err instanceof Error ? err.message : String(err)}`);
    }
    return null;
  }
}

export async function me(): Promise<User | null> {
  try {
    const res: AxiosResponse = await axios.get(
      `${APIRoutes.AUTH_ROUTE}/${APIRoutes.PATH.ME}`
    );
    const responseData: User = await res.data;

    LogDev(responseData);
    return responseData;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      ErrorDev(`Axios error: ${e.message}`);
    } else {
      ErrorDev(`Unexpected error: ${e instanceof Error ? e.message : String(e)}`);
    }
    return null;
  }
}

export async function fetchUserProductCollection(): Promise<Collection<UserProduct> | null> {
  try {
    const res: AxiosResponse = await axios.get(`${APIRoutes.PATH.USER_PRODUCTS}`);
    const userProductCollection: Collection<UserProduct> = res.data;

    LogDev(userProductCollection);
    return userProductCollection;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      ErrorDev(`Axios error: ${err.message}`);
    } else {
      ErrorDev(`Unexpected error: ${err instanceof Error ? err.message : String(err)}`);
    }
  }

  return null;
}
