import { Token, UserProduct } from '@dewantara-types/models';
import {
  AuthenticatingAction,
  AuthState,
  SetActiveChildAction,
  SetTokensAction,
  SetUserAction,
  UserProductsAction,
} from '@dewantara-types/store';
import { createSlice } from '@reduxjs/toolkit';
import { LOCAL_ACCESS_TOKEN, LOCAL_REFRESH_TOKEN } from 'dewantara-constants';
import { ErrorDev } from 'logging/Logging';

const authInitialState: AuthState = (() => {
  let accessToken: Token | null = null;
  try {
    const accessState: string | null = localStorage.getItem(LOCAL_ACCESS_TOKEN);
    accessToken = accessState ? (JSON.parse(accessState) as Token) : null;
  } catch (e) {
    ErrorDev(`Failed to parse access token from localStorage: ${e}`);
  }

  let refreshToken: Token | null = null;
  try {
    const refreshState: string | null = localStorage.getItem(LOCAL_REFRESH_TOKEN);
    refreshToken = refreshState ? (JSON.parse(refreshState) as Token) : null;
  } catch (e) {
    ErrorDev(`Failed to parse refresh token from localStorage: ${e}`);
  }

  return {
    isAuthenticating: false,
    accessToken: accessToken,
    refreshToken: refreshToken,
    user: null,
    activeChild: null,
    userProducts: null,
  };
})();

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    authenticating(state: AuthState, action: AuthenticatingAction) {
      state.isAuthenticating = action.payload;
    },
    setTokens(state: AuthState, action: SetTokensAction) {
      const { accessToken, refreshToken } = action.payload;

      const newAccessToken = accessToken;
      newAccessToken.expiresIn = newAccessToken.expiresIn * 1000 + Date.now();
      localStorage.setItem(LOCAL_ACCESS_TOKEN, JSON.stringify(newAccessToken));

      const newRefreshToken = refreshToken;
      newRefreshToken.expiresIn = newRefreshToken.expiresIn * 1000 + Date.now();
      localStorage.setItem(LOCAL_REFRESH_TOKEN, JSON.stringify(newRefreshToken));

      state.accessToken = newAccessToken;
      state.refreshToken = newRefreshToken;
    },
    setUser(state: AuthState, action: SetUserAction) {
      state.user = action.payload;
    },
    setActiveChild(state: AuthState, action: SetActiveChildAction) {
      state.activeChild = action.payload;
    },
    setUserProducts(state: AuthState, action: UserProductsAction) {
      state.userProducts = action.payload;
    },
    addUserProduct(state: AuthState, action: UserProductsAction) {
      const userProducts: UserProduct[] = state.userProducts
        ? [...state.userProducts, ...action.payload]
        : [...action.payload];
      state.userProducts = userProducts;
    },
    clearAuth(state: AuthState) {
      localStorage.removeItem(LOCAL_ACCESS_TOKEN);
      localStorage.removeItem(LOCAL_REFRESH_TOKEN);
      state.accessToken = null;
      state.refreshToken = null;
      state.isAuthenticating = false;
      state.activeChild = null;
      state.user = null;
      state.userProducts = null;
    },
  },
});

export const authActions = authSlice.actions;
