import { MuteAudioAction, SettingState } from '@dewantara-types/store';
import { createSlice } from '@reduxjs/toolkit';
import { LOCAL_SETTING } from 'dewantara-constants';
import { ErrorDev } from 'logging/Logging';

const settingInitialState: SettingState = (() => {
  const settingJson: string | null = localStorage.getItem(LOCAL_SETTING);
  try {
    if (settingJson) {
      const localSettingState = JSON.parse(settingJson) as SettingState;
      return localSettingState;
    }
  } catch (e) {
    ErrorDev(`Failed to parse access token from localStorage: ${e}`);
  }

  return {
    muteAudio: false,
  };
})();

export const settingSlice = createSlice({
  name: 'setting',
  initialState: settingInitialState,
  reducers: {
    setMuteAudio(state: SettingState, action: MuteAudioAction) {
      state.muteAudio = action.payload;
      localStorage.setItem(LOCAL_SETTING, JSON.stringify(state));
    },
  },
});

export const settingActions = settingSlice.actions;
