export namespace ApplicationRoutes {
  export const ROOT_ROUTE: string = '/';
  export const DASHBOARD_ROUTE: string = '/';
  export const STORE_ROUTE: string = 'toko/';
  export const ROOT_STORE_ROUTE: string = '/' + STORE_ROUTE;
  export const USER_ROUTE: string = 'pengguna/';
  export const ROOT_USER_ROUTE: string = '/' + USER_ROUTE;
  export const LIBRARY_ROUTE: string = 'perpustakaan/';
  export const ROOT_LIBRARY_ROUTE: string = '/' + LIBRARY_ROUTE;
  export const PRODUCT_DETAIL_ROUTE: string = 'buku/:code/';
  export const ROOT_PRODUCT_DETAIL_ROUTE: string = '/' + PRODUCT_DETAIL_ROUTE;
  export const PRODUCT_DETAIL_PAGE_ROUTE: string = 'halaman/';
  export const ROOT_PRODUCT_DETAIL_PAGE_ROUTE: string =
    ROOT_PRODUCT_DETAIL_ROUTE + 'halaman/';
  export const AUTH_ROUTE: string = '/auth/';
  export const REGISTER_ROUTE: string = AUTH_ROUTE + 'register/';
  export const LOGIN_ROUTE: string = AUTH_ROUTE + 'login/';
  export const PORTAL_ROUTE: string = 'portal/';
  export const ROOT_PORTAL_ROUTE: string = '/' + PORTAL_ROUTE;
  export const ADD_PRODUCT_ROUTE: string = 'tambah/';
  export const ROOT_ADD_PRODUCT_ROUTE: string = ROOT_PORTAL_ROUTE + ADD_PRODUCT_ROUTE;
  export const EDIT_PRODUCT_ROUTE: string = 'edit/';
  export const ROOT_EDIT_PRODUCT_ROUTE: string = ROOT_PORTAL_ROUTE + EDIT_PRODUCT_ROUTE;
  export const PAGE_BUILDER_ROUTE: string = 'page-builder/';
  export const ROOT_PAGE_BUILDER_ROUTE: string = ROOT_PORTAL_ROUTE + PAGE_BUILDER_ROUTE;
  export const PAGE_LISTENER_ROUTE: string = 'page-listener/';
  export const ROOT_PAGE_LISTENER_ROUTE: string = ROOT_ROUTE + PAGE_LISTENER_ROUTE;
  export const CATCH_ALL_ROUTE: string = '*';

  export const PRODUCT_PARAM: string = 'pcode';
  export const MODULE_PARAM: string = 'mcode';
}
