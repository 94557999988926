import {
  ProductBuilderAction,
  ProductBuilderState,
  ProductModuleBuilder,
  RemoveBuilderAction,
  UpdateAnswersAction,
  UpdateBackgroundAction,
  UpdateObjectsAction,
} from '@dewantara-types/store';
import { createSlice } from '@reduxjs/toolkit';
import { LOCAL_PRODUCT_MODULES } from 'dewantara-constants';
import { buildProductBuilder } from 'utils/product-module-utils';

const productBuilderInitialState: ProductBuilderState = (() => {
  const productBuilderJson: string =
    localStorage.getItem(LOCAL_PRODUCT_MODULES) ??
    JSON.stringify({ productModules: [] } as ProductBuilderState);
  const productBuilder: ProductBuilderState = buildProductBuilder(productBuilderJson);
  return productBuilder;
})();

export const productBuilderSlice = createSlice({
  name: 'productModules',
  initialState: productBuilderInitialState,
  reducers: {
    saveLocal(state: ProductBuilderState, action: ProductBuilderAction) {
      const { productCode, module } = action.payload;

      const moduleBuilder: ProductModuleBuilder | undefined = state.productModules.find(
        (pm: ProductModuleBuilder) =>
          pm.productCode === productCode && pm.module.code === module.code
      );

      let updatedModuleBuilders: ProductModuleBuilder[] | null = null;
      if (moduleBuilder) {
        updatedModuleBuilders = state.productModules.map((pm: ProductModuleBuilder) =>
          pm.module.code === moduleBuilder.module.code
            ? { productCode: moduleBuilder.productCode, module: module }
            : pm
        );
      } else {
        updatedModuleBuilders = [...state.productModules, action.payload];
      }

      if (updatedModuleBuilders) {
        state.productModules = updatedModuleBuilders;
        localStorage.setItem(LOCAL_PRODUCT_MODULES, JSON.stringify(state));
      }
    },
    updateBackgroundLocal(state: ProductBuilderState, action: UpdateBackgroundAction) {
      const { code, backgroundImg } = action.payload;

      const updatedProductModules = [...state.productModules].map(
        (pm: ProductModuleBuilder) => {
          if (pm.module.code === code) {
            return { ...pm, module: { ...pm.module, backgroundImg: backgroundImg } };
          }
          return pm;
        }
      );

      state.productModules = updatedProductModules;
      localStorage.setItem(LOCAL_PRODUCT_MODULES, JSON.stringify(state.productModules));
    },
    updateObjectsLocal(state: ProductBuilderState, action: UpdateObjectsAction) {
      const { code, objects } = action.payload;
      state.productModules = state.productModules.map((pm: ProductModuleBuilder) => {
        if (pm.module.code === code) {
          return {
            productCode: pm.productCode,
            module: { ...pm.module, objects: objects },
          };
        }
        return pm;
      });
      localStorage.setItem(LOCAL_PRODUCT_MODULES, JSON.stringify(state));
    },
    removeLocal(state: ProductBuilderState, action: RemoveBuilderAction) {
      const { code } = action.payload;
      const updatedModuleBuilders: ProductModuleBuilder[] = state.productModules.filter(
        (pm: ProductModuleBuilder) => pm.module.code !== code
      );
      state.productModules = updatedModuleBuilders;
      if (updatedModuleBuilders.length > 0) {
        localStorage.setItem(LOCAL_PRODUCT_MODULES, JSON.stringify(state));
      } else {
        localStorage.removeItem(LOCAL_PRODUCT_MODULES);
      }
    },
    updateAnswerLocal(state: ProductBuilderState, action: UpdateAnswersAction) {
      const { moduleCode, answers } = action.payload;
      state.productModules = state.productModules.map((pm: ProductModuleBuilder) => {
        if (pm.module.code === moduleCode) {
          return {
            productCode: pm.productCode,
            module: { ...pm.module, answers },
          };
        }
        return pm;
      });
      localStorage.setItem(LOCAL_PRODUCT_MODULES, JSON.stringify(state));
    },
  },
});

export const productBuilderActions = productBuilderSlice.actions;
