import React from 'react';
import { Card } from '@material-tailwind/react';
import { Props } from '@dewantara-types/props';

export function DashboardCard(props: Props): React.ReactElement {
  return (
    <Card
      className={`w-full p-2.5 sm:p-6 md:py-10 lg:py-8 shadow-none xl:shadow-md ${props.className}`}
    >
      {props.children}
    </Card>
  );
}

export default DashboardCard;
