import { DewantaraInputProps } from '@dewantara-types/props';
import { Input } from '@material-tailwind/react';
import React from 'react';
import DewantaraInputPassword from './DewantaraInputPassword';
import { INPUT_STYLES } from 'dewantara-constants';

export function DewantaraInput(props: DewantaraInputProps): React.ReactElement {
  const { className, type, step, placeholder, inputHook } = props;

  const textSizeClass = INPUT_STYLES.TEXT_SIZE_CLASSES;
  const inputPaddingClass = INPUT_STYLES.PADDING_CLASSES;

  if (type === 'password') return <DewantaraInputPassword {...props} />;

  function inputChangeHandler(e: Events.InputChangeEvent) {
    inputHook?.inputChangeHandler(e.target.value.toString());
  }

  function inputBlurHandler(e: Events.InputBlurEvent) {
    inputHook?.inputBlurHandler();
  }

  return (
    <Input
      type={type ?? 'text'}
      step={step}
      value={inputHook?.value}
      placeholder={placeholder}
      className={`focus:!border-d-blue-primary ${textSizeClass} ${inputPaddingClass} ${className}`}
      labelProps={{
        className: 'before:content-none after:content-none',
      }}
      containerProps={{
        className: '!min-w-0 flex items-center justify-center mt-1 md:lg-2 lg:mt-4',
      }}
      onChange={inputChangeHandler}
      onBlur={inputBlurHandler}
    />
  );
}

export default DewantaraInput;
