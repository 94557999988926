import { AppStatus } from 'dewantara-constants';

function Log(message: any) {
  console.log(message);
}

function Error(message: any) {
  console.error(message);
}

function LogDev(message: any) {
  if (AppStatus.IS_DEV) {
    Log(message);
  }
}

function ErrorDev(message: any) {
  if (AppStatus.IS_DEV) {
    console.error(message);
  }
}

export { Log, Error, LogDev, ErrorDev };
