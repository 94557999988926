export const SCREEN_SIZE = {
  SM: 640,
  MD: 768,
  LG: 1024,
  XL: 1280,
  '2XL': 1536,
};

export const AUTH_STYLES = {
  PADDING_X: 'px-5 sm:px-16',
  PADDING_Y: 'py-8 sm:py-12 md:py-16 lg: py-20',
};

export const INPUT_STYLES = {
  TEXT_SIZE_CLASSES: 'text-base sm:text-xl md:text-2xl xl:text-xl font-normal',
  PADDING_CLASSES: 'px-3 sm:px-4 md:px-5 py-2.5 sm:py-6 md:py-7 lg:py-8 xl:py-6',
};

export const LABEL_STYLES = {
  TEXT_CLASSES: 'text-base sm:text-xl md:text-3xl',
};

export const EMPTY_CONTENT_STYLES = {
  HEADER_CLASSES:
    'text-lg sm:text-xl md:text-3xl lg:text-4xl xl:text-3xl text-center font-bold',
  BODY_CLASSES:
    'text-sm sm:text-base md:text-xl lg:text-2xl xl:text-xl text-center font-normal',
};

export const ARROW_BACK_SIZE_STYLE =
  '!h-8 sm:!h-10 md:!h-12 lg:!h-12 !w-8 sm:!w-10 md:!w-12 lg:!w-12';
