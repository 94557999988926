import { APIRoutes } from 'dewantara-constants';
import { Product, ScreenTime } from '@dewantara-types/models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { uiActions } from 'store/slices/ui-slice';
import { ErrorDev } from 'logging/Logging';
import { _fetchData, _updateData } from 'hooks/use-api-request';

export const fetchScreenTime = createAsyncThunk(
  'ui/fetchScreenTime',
  async (childCode: string, { dispatch }) => {
    const todayScreenTime = await _fetchData<ScreenTime>(
      `${APIRoutes.PATH.SCREEN_TIME}/${childCode}/today/`
    );
    if (todayScreenTime) dispatch(uiActions.setScreenTime(todayScreenTime));
  }
);

export const updateScreenTime = createAsyncThunk(
  'ui/updateScreenTime',
  async (
    { screenTimeCode, payload }: { screenTimeCode: string; payload: ScreenTime },
    { dispatch }
  ) => {
    const updatedScreenTime = await _updateData<ScreenTime>(
      `${APIRoutes.PATH.SCREEN_TIME}/${screenTimeCode}`,
      payload
    );
    if (updatedScreenTime) dispatch(uiActions.setScreenTime(updatedScreenTime));
    else ErrorDev('Something went wrong updating screen time data.');
  }
);

export const fetchProductDetail = createAsyncThunk(
  'ui/fetchProductDetail',
  async (productCode: string, { dispatch }) => {
    const product = await _fetchData<Product>(
      `${APIRoutes.PATH.PRODUCTS}/${productCode}`
    );
    if (product) dispatch(uiActions.setProductDetail(product));
  }
);
