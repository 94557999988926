import {
  CheckboxPropsBuilder,
  FontBuilder,
} from '@dewantara-types/models/product-modules';
import { IconComponentType } from '@dewantara-types/props';
import { RectangleRounded } from '@mui/icons-material';

export const DEFAULT_CANVAS = {
  WIDTH: 600,
  HEIGHT: 800,
};

export const DEFAULT_FONT_STYLE: FontBuilder = {
  size: 20,
  bold: false,
  italic: false,
  underline: false,
  wrap: false,
  align: 'left',
  weight: 500,
  color: 'black',
};

export const DEFAULT_OBJECT_COLOR: string[] = [
  'pink',
  'orchid',
  'red',
  'orange',
  'yellow',
  'cyan',
  'blue',
  'lime',
  'teal',
  'green',
  'olive',
  'indigo',
  'black',
];

export const BUILDER_SHAPES: { label: string; color: string; icon: IconComponentType }[] =
  [
    {
      label: 'Rectangle',
      color: 'blue',
      icon: RectangleRounded,
    },
    {
      label: 'Circle',
      color: 'red',
      icon: RectangleRounded,
    },
    {
      label: 'Triangle',
      color: 'green',
      icon: RectangleRounded,
    },
    {
      label: 'Ellipse',
      color: 'cyan',
      icon: RectangleRounded,
    },
    {
      label: 'Hexagon',
      color: 'orange',
      icon: RectangleRounded,
    },
    {
      label: 'Half circle',
      color: 'teal',
      icon: RectangleRounded,
    },
  ];

export const DEFAULT_CHECKBOX_PROPS: CheckboxPropsBuilder = {
  isChecked: false,
};

export const PRD_MODULE_PREFIX: string = 'PRD_MOD_';
const COMMON_MODULE_RULES = ['shape', 'text', 'image'];
export const MODULES = {
  checkbox: [...COMMON_MODULE_RULES, 'checkbox'],
  counting: [...COMMON_MODULE_RULES, 'counter'],
  'drag-and-drop': [...COMMON_MODULE_RULES, 'drag-and-drop'],
  coloring: [...COMMON_MODULE_RULES, 'coloring'],
};

export const NO_REMOVE_ANSWER: string[] = ['counting', 'drag-and-drop'];
