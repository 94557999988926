import { DewantaraTitleProps } from '@dewantara-types/props';
import { Typography } from '@material-tailwind/react';
import React from 'react';

export function DewantaraTitle(props: DewantaraTitleProps): React.ReactElement {
  const { className, children, size, color } = props;

  const textColor: string = color ? `text-d-${color}-primary` : 'text-d-gray-primary';

  const textSize: string =
    size === 'md'
      ? 'text-xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-4xl'
      : 'text-base sm:text-xl md:text-2xl lg:text-3xl xl:text-2xl';

  return (
    <Typography className={`font-bold ${textSize} ${textColor} ${className}`}>
      {children}
    </Typography>
  );
}

export default DewantaraTitle;
