import { combineReducers } from 'redux';
import { uiSlice } from './slices/ui-slice';
import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './slices/auth-slice';
import { productBuilderSlice } from './slices/product-builder-slice';
import { settingSlice } from './slices/setting-slice';
import { browserSlice } from './slices/browser-slice';

export const reducers = combineReducers({
  ui: uiSlice.reducer,
  auth: authSlice.reducer,
  productBuilder: productBuilderSlice.reducer,
  setting: settingSlice.reducer,
  browser: browserSlice.reducer,
});

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
