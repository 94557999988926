import React from 'react';
import { IconButton as MaterialIconButton } from '@material-tailwind/react';
import { DewantaraButtonIconProps } from '@dewantara-types/props';

export function DewantaraIconButton(props: DewantaraButtonIconProps): React.ReactElement {
  const { className, variant, onClick, icon } = props;

  return (
    <div className="relative w-fit h-fit">
      <MaterialIconButton
        className={`max-w-full max-h-full z-10 shadow-none normal-case rounded-full border flex ${
          variant === 'primary' && 'bg-d-blue-primary text-white'
        } ${
          variant === 'secondary' && 'bg-d-white-primary text-d-blue-primary'
        } ${className}`}
        onClick={onClick}
        ripple={false}
      >
        {icon}
      </MaterialIconButton>
      <span className="rounded-full bg-d-black-primary bg-opacity-25 absolute top-0 -bottom-1 left-0 -right-1" />
    </div>
  );
}

export default DewantaraIconButton;
