import { DewantaraTooltipProps } from '@dewantara-types/props';
import { Card, Tooltip, Typography } from '@material-tailwind/react';
import React from 'react';

export function DewantaraTooltip(props: DewantaraTooltipProps): React.ReactElement {
  const { className, tooltipClassName, content } = props;

  return (
    <Tooltip
      content={
        <div
          className={`max-w-3xs sm:max-w-2xs md:max-w-xs lg:max-w-md ${tooltipClassName}`}
        >
          <Typography className="text-sm sm:text-base md:text-lg lg:text-2xl xl:text-xl tracking-wide">
            {content}
          </Typography>
        </div>
      }
      placement="bottom-start"
    >
      <Card
        className={`shadow-sm h-5 sm:h-6 md:h-8 lg:h-10 xl:h-6 w-5 sm:w-6 md:w-8 lg:w-10 xl:w-6 flex items-center justify-center rounded-full cursor-pointer ${className}`}
      >
        <Typography className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-base font-bold">
          ?
        </Typography>
      </Card>
    </Tooltip>
  );
}

export default DewantaraTooltip;
