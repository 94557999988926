import { DewantaraDatePickerProps } from '@dewantara-types/props';
import React, { useState } from 'react';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';

export function DewantaraDatePicker(props: DewantaraDatePickerProps): React.ReactElement {
  const { className, inputHook } = props;

  const [datePicker, setDatePicker] = useState<DateValueType>({
    startDate: inputHook ? new Date(inputHook.value) : new Date(),
    endDate: null,
  });

  function inputChangeHandler(newVal: DateValueType) {
    setDatePicker(newVal);
    inputHook &&
      inputHook.inputChangeHandler(
        newVal?.startDate?.toLocaleDateString() ?? inputHook.value
      );
  }

  return (
    <div className={`border border-gray-300 rounded-lg ${className}`}>
      <Datepicker
        i18n="id"
        asSingle
        useRange={false}
        displayFormat="DD/MM/YYYY"
        maxDate={new Date()}
        placeholder={datePicker?.startDate?.toLocaleDateString()}
        value={datePicker}
        onChange={inputChangeHandler}
      />
    </div>
  );
}

export default DewantaraDatePicker;
