import { Props } from '@dewantara-types/props';
import { RootState } from '@dewantara-types/store';
import { ASSETS_PATH } from 'dewantara-constants';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function PageBackButton(props: Props): React.ReactElement {
  const { className } = props;

  const navigate = useNavigate();

  const { prevPath } = useSelector((state: RootState) => state.browser);

  return (
    <span
      className={`bg-d-navy-primary rounded-full p-2 z-10 cursor-pointer ${className}`}
      onClick={() => prevPath && navigate(prevPath)}
    >
      <img
        className="h-5 sm:h-6 w-5 sm:w-6"
        src={`${ASSETS_PATH}/icons/arrow-back.png`}
        alt="arrow-back.png"
      />
    </span>
  );
}

export default PageBackButton;
