import React from 'react';
import { Button as MaterialButton } from '@material-tailwind/react';
import { DewantaraButtonProps } from '@dewantara-types/props';

function DewantaraButtonLoading(): React.ReactElement {
  return (
    <span className="w-2.5 h-2.5 mx-auto my-auto rounded-full block box-border animate-button-loading" />
  );
}

export function DewantaraButton(props: DewantaraButtonProps): React.ReactElement {
  const {
    className,
    variant = 'primary',
    color = 'blue',
    type,
    isLoading = false,
    children,
    onClick,
  } = props;

  const colorClasses = `border-d-${color}-primary ${
    variant === 'primary' && `bg-d-${color}-primary text-white`
  } ${variant === 'secondary' && `bg-d-white-primary text-d-${color}-primary`}`;
  const disabledClasses = 'disabled:bg-opacity-10 disabled:border-opacity-10';
  const disabledShadowClasses = 'after:disabled:bg-opacity-0';

  return (
    <div
      className={`relative z-0 after:content-[''] after:rounded-full after:bg-d-black-primary after:bg-opacity-25 ${disabledShadowClasses} after:absolute after:top-0 after:-bottom-[0.2rem] after:left-0.5 after:-right-0.5 after:-z-10 ${className}`}
    >
      <MaterialButton
        className={`w-full !h-full min-h-[3rem] normal-case shadow-none py-2.5 text-xs sm:text-base md:text-lg lg:text-xl xl:text-lg border rounded-full ${disabledClasses} ${colorClasses} ${className}`}
        onClick={onClick}
        ripple={false}
        type={type}
        disabled={isLoading}
      >
        {isLoading ? <DewantaraButtonLoading /> : children}
      </MaterialButton>
    </div>
  );
}

export default DewantaraButton;
