import { BrowserState, PrevPathAction } from '@dewantara-types/store';
import { createSlice } from '@reduxjs/toolkit';

const browserInitialState: BrowserState = {};

export const browserSlice = createSlice({
  name: 'browser',
  initialState: browserInitialState,
  reducers: {
    setCurrentPath(state: BrowserState, action: PrevPathAction) {
      const currentPath = state.currentPath;
      state.prevPath = currentPath;
      state.currentPath = action.payload;
    },
  },
});

export const browserActions = browserSlice.actions;
