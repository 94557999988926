import { DewantaraButtonProps, DewantaraPaginationProps } from '@dewantara-types/props';
import { ArrowLeftRounded, ArrowRightRounded } from '@mui/icons-material';
import React from 'react';

function Page(props: DewantaraButtonProps): React.ReactElement {
  const { className, disabled, children } = props;

  const textSizeClass = 'text-base md:text-lg';

  return (
    <button
      className={`w-10 md:w-11 lg:w-12 xl:w-10 h-10 md:h-11 lg:h-12 xl:h-10 border rounded-md flex items-center justify-center ${textSizeClass} ${className} ${
        disabled
          ? 'text-d-gray-secondary border-d-gray-secondary'
          : 'text-d-yellow-primary border-d-yellow-primary'
      }`}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export function DewantaraPagination(props: DewantaraPaginationProps): React.ReactElement {
  const { className } = props;

  return (
    <div className={`mx-auto flex items-center gap-2 ${className}`}>
      <Page disabled>
        <ArrowLeftRounded className="!h-14 !w-14" />
      </Page>
      <Page>1</Page>
      <Page disabled>
        <ArrowRightRounded className="!h-14 !w-14" />
      </Page>
    </div>
  );
}

export default DewantaraPagination;
