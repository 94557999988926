import { createSlice } from '@reduxjs/toolkit';
import {
  UIState,
  ScreenTimeAction,
  OpenScreenTimeDialogAction,
  ProductDetailAction,
  GoogleAction,
  OpenFeedbackDialogAction,
} from '@dewantara-types/store';
import { ScreenTime } from '@dewantara-types/models';
import {
  LOCAL_FEEDBACK_TRIGGER1,
  SCREEN_TIME_DANGER_VALUE,
  SCREEN_TIME_WARNING_VALUE,
} from 'dewantara-constants';

// Initial state
const uiInitialState: UIState = (() => {
  const localTrigger1: boolean = localStorage.getItem(LOCAL_FEEDBACK_TRIGGER1) !== null;

  return {
    screenTime: { totalTime: -1, createdAt: new Date() },
    openScreenTimeDialog: false,
    openFeedbackDialogTrigger1: localTrigger1,
    openFeedbackDialogTrigger2: false,
    productDetail: null,
    isGoogleInitiated: false,
  };
})();

// Reducer
export const uiSlice = createSlice({
  name: 'ui',
  initialState: uiInitialState,
  reducers: {
    setScreenTime(state: UIState, action: ScreenTimeAction) {
      const screenTime: ScreenTime = action.payload;
      state.screenTime = screenTime;

      if (
        screenTime.totalTime === SCREEN_TIME_WARNING_VALUE * 60 ||
        screenTime.totalTime === SCREEN_TIME_DANGER_VALUE * 60
      )
        state.openScreenTimeDialog = true;
    },
    setScreenTimeDialog(state: UIState, action: OpenScreenTimeDialogAction) {
      state.openScreenTimeDialog = action.payload;
    },
    setFeedbackDialog(state: UIState, action: OpenFeedbackDialogAction) {
      const { trigger1, trigger2 } = action.payload;
      if (trigger1 !== undefined) {
        state.openFeedbackDialogTrigger1 = trigger1;

        if (trigger1) localStorage.setItem(LOCAL_FEEDBACK_TRIGGER1, '1');
        else localStorage.removeItem(LOCAL_FEEDBACK_TRIGGER1);
      }
      if (trigger2 !== undefined) state.openFeedbackDialogTrigger2 = trigger2;
    },
    setProductDetail(state: UIState, action: ProductDetailAction) {
      state.productDetail = action.payload;
    },
    setInitiateGoogle(state: UIState, action: GoogleAction) {
      state.isGoogleInitiated = action.payload;
    },
  },
});

export const uiActions = uiSlice.actions;
