import { DewantaraDialogProps } from '@dewantara-types/props';
import { Dialog, DialogBody, DialogFooter } from '@material-tailwind/react';
import React from 'react';
import DewantaraButton from './DewantaraButton';
import DewantaraLabel from './DewantaraLabel';

export function DewantaraDialog(props: DewantaraDialogProps): React.ReactElement {
  const {
    className,
    open,
    imgSrc,
    imgAlt,
    message,
    primaryButtonText,
    secondaryButtonText,
    onHandleDialog,
    primaryButtonAction,
    secondaryButtonAction,
  } = props;

  return (
    <Dialog
      className={`!min-w-0 !w-full sm:max-w-lg md:max-w-xl lg:max-w-2xl rounded-3xl p-6 ${className}`}
      size="sm"
      open={open}
      handler={onHandleDialog}
    >
      <DialogBody className="flex flex-col items-center">
        <img className="max-w-3xs md:max-w-2xs m-10" src={imgSrc} alt={imgAlt} />
        <DewantaraLabel className="text-center">{message}</DewantaraLabel>
      </DialogBody>
      <DialogFooter>
        {' '}
        <div className="w-full flex flex-row gap-4">
          {secondaryButtonText && (
            <DewantaraButton
              className={!!primaryButtonText ? 'basis-1/2' : 'w-full'}
              variant="secondary"
              onClick={secondaryButtonAction}
            >
              {secondaryButtonText}
            </DewantaraButton>
          )}
          {primaryButtonText && (
            <DewantaraButton
              className={!!secondaryButtonText ? 'basis-1/2' : 'w-full'}
              variant="primary"
              onClick={primaryButtonAction}
            >
              {primaryButtonText}
            </DewantaraButton>
          )}{' '}
        </div>
      </DialogFooter>
    </Dialog>
  );
}

export default DewantaraDialog;
