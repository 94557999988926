import { Collection } from '@dewantara-types/apis';
import { FileData } from '@dewantara-types/models';
import axios, { AxiosHeaders, AxiosResponse } from 'axios';
import { ErrorDev, LogDev } from 'logging/Logging';

export async function _fetchCollection<T>(
  route: string,
  verifyAccessToken?: () => Promise<void>
): Promise<Collection<T> | null> {
  try {
    if (verifyAccessToken) await verifyAccessToken();

    const res: AxiosResponse = await axios.get(route);
    const responseData: Collection<T> = res.data;
    LogDev(responseData);
    return responseData;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      ErrorDev(`Axios error: ${err.message}`);
    } else {
      ErrorDev(`Unexpected error: ${err instanceof Error ? err.message : String(err)}`);
    }
    return null;
  }
}

export async function _fetchData<T>(
  route: string,
  verifyAccessToken?: () => Promise<void>
): Promise<T | null> {
  try {
    if (verifyAccessToken) await verifyAccessToken();

    const res: AxiosResponse = await axios.get(route);
    const responseData: T = await res.data;
    LogDev(responseData);
    return responseData;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      ErrorDev(`Axios error: ${err.message}`);
    } else {
      ErrorDev(`Unexpected error: ${err instanceof Error ? err.message : String(err)}`);
    }
    return null;
  }
}

export async function _insertData<T>(
  route: string,
  payload: any,
  verifyAccessToken?: () => Promise<void>
): Promise<T | null> {
  try {
    if (verifyAccessToken) await verifyAccessToken();

    const res: AxiosResponse = await axios.post(route, payload);
    const responseData: T = await res.data;
    LogDev(responseData);
    return responseData;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      ErrorDev(`Axios error: ${err.message}`);
    } else {
      ErrorDev(`Unexpected error: ${err instanceof Error ? err.message : String(err)}`);
    }
    return null;
  }
}

export async function _updateData<T>(
  route: string,
  payload: any,
  verifyAccessToken?: () => Promise<void>
): Promise<T | null> {
  try {
    if (verifyAccessToken) await verifyAccessToken();

    const res: AxiosResponse = await axios.put(route, payload);
    const responseData: T = await res.data;
    LogDev(responseData);

    return responseData;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      ErrorDev(`Axios error: ${err.message}`);
    } else {
      ErrorDev(`Unexpected error: ${err instanceof Error ? err.message : String(err)}`);
    }
    return null;
  }
}

export async function _deleteData(
  route: string,
  verifyAccessToken?: () => Promise<void>
): Promise<boolean> {
  try {
    if (verifyAccessToken) await verifyAccessToken();

    const res: AxiosResponse = await axios.delete(route);
    LogDev(res.status);
    return res.status === 204;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      ErrorDev(`Axios error: ${err.message}`);
    } else {
      ErrorDev(`Unexpected error: ${err instanceof Error ? err.message : String(err)}`);
    }
    return false;
  }
}

export async function _uploadFile<T>(
  route: string,
  payload: FileData,
  verifyAccessToken?: () => Promise<void>
): Promise<T | null> {
  try {
    if (verifyAccessToken) await verifyAccessToken();

    const headers = new AxiosHeaders();
    headers.set('Accept', '*/*');
    headers.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    if (payload.file) formData.append('file', payload.file);

    const res: AxiosResponse = await axios.post(route, formData, { headers: headers });
    const responseData: T = await res.data;
    LogDev(responseData);
    return responseData;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      ErrorDev(`Axios error: ${err.message}`);
    } else {
      ErrorDev(`Unexpected error: ${err instanceof Error ? err.message : String(err)}`);
    }
    return null;
  }
}
