import { IUseGoogleManager } from '@dewantara-types/hooks';
import { User } from '@dewantara-types/models';
import { ProductModuleType } from '@dewantara-types/models/product-modules';
import { AppDispatch, RootState } from '@dewantara-types/store';
import { GOOGLE } from 'dewantara-constants';
import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { uiActions } from 'store/slices/ui-slice';

const useGoogleManager = (): IUseGoogleManager => {
  const dispatch = useDispatch<AppDispatch>();
  const { isGoogleInitiated } = useSelector((state: RootState) => state.ui);
  const { user } = useSelector((state: RootState) => state.auth);

  const loggedPageAt = useRef<number>(-1);

  function init() {
    ReactGA.initialize(GOOGLE.ANALYTICS_ID);
    dispatch(uiActions.setInitiateGoogle(true));
  }

  function setUser(user: User) {
    ReactGA.set({
      userId: user.code,
      user_code: user.code,
      user_email: user.email,
      user_role: user.role,
    });
    ReactGA.gtag('config', GOOGLE.ANALYTICS_ID, {
      user_id: user.code,
      user_email: user.email,
      user_role: user.role,
    });
  }

  function clearUser() {
    ReactGA.set({ userId: null, userEmail: null, userRole: null });
  }

  function logPageEvent(page: string, route: string) {
    loggedPageAt.current = Date.now();

    ReactGA.event('page_view', {
      category: 'Page view',
      action: `View ${page}`,
      label: `Log ${page}`,
      value: route,
      nonInteraction: true,
    });
  }

  function sendPageDurationEvent(page: string) {
    const duration =
      loggedPageAt.current < 0
        ? loggedPageAt.current
        : (Date.now() - loggedPageAt.current) / 1000;

    ReactGA.event('page_session', {
      category: 'Page session',
      action: 'duration',
      label: `Time spent on ${page}`,
      value: duration,
      nonInteraction: true,
    });
  }

  function trackProductModule(
    type: ProductModuleType,
    duration: number,
    finished?: boolean
  ) {
    if (finished) {
      ReactGA.event('product_module_finished', {
        category: `Product module finished event`,
        action: `Module ${type.replaceAll('-', ' ')}`,
        label: user?.code,
        value: duration,
        nonInteraction: true,
      });
    } else {
      ReactGA.event('product_module_canceled', {
        category: `Product module canceled event`,
        action: `Module ${type.replaceAll('-', ' ')}`,
        label: user?.code,
        value: duration,
        nonInteraction: true,
      });
    }
  }

  useEffect(() => {
    if (user)
      ReactGA.set({ userId: user.code, userEmail: user.email, userRole: user.role });
  });

  return {
    isInitiated: isGoogleInitiated,
    init,
    setUser,
    clearUser,
    logPageEvent,
    sendPageDurationEvent,
    trackProductModule,
  };
};

export default useGoogleManager;
