import { AppDispatch, RootState } from '@dewantara-types/store';
import { ApplicationRoutes, ROLES } from 'dewantara-constants';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { fetchUser } from 'store/actions';

function PortalLayout(): React.ReactElement {
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const { user, accessToken } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (accessToken && !user) {
      if (accessToken && !user) dispatch(fetchUser());
    }
  }, [accessToken, user, dispatch]);

  if (!user) return <div />;
  if (user && user.role !== ROLES.DEV) navigate(ApplicationRoutes.ROOT_ROUTE);

  return (
    <div className="min-w-[45rem] h-full min-h-screen mx-16 my-12">
      <Outlet />
    </div>
  );
}

export default PortalLayout;
